<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">

        <svg version="1.1" class="ifood-logo" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 2500 1500" style="enable-background:new 300 300 1000 1000; margin-left: 10px;" xml:space="preserve">
          <path class="st0" d="M0 758.5h190.3L296 232.6H105.7L0 758.5zm116.2-588.7h191.1l31.4-157.3h-191l-31.5 157.3zm148.1 736.6h190.3l116.3-568.2h142.7l21-105.7h-140l5.3-23.8c7.9-44.8 23.8-95.1 95.1-95.1 42.3 0 81.9 2.6 121.6 21.1l21.2-111C793.6 8.2 747.1.2 700.3 0 547 0 441.4 89.9 401.7 232.6h-66.1l-21.1 105.7h66.1L264.3 906.4z"/>
          <path class="st0" d="M866.8 769c224.6 0 380.6-203.5 380.6-370 0-124.2-113.6-177.1-224.6-177.1-245.8 0-380.6 219.5-380.6 370 0 124.3 116.3 177.1 224.6 177.1m602.6 0C1694 769 1850 565.5 1850 399c0-124.2-116.3-177.1-227.3-177.1-245.8 0-380.5 219.3-380.5 370-.1 124.3 118.9 177.1 227.2 177.1m689.7-10.5h187.6L2500 10.7h-190.3l-44.9 222-79.3-7.9c-185 0-354.1 237.8-354.1 407 0 68.7 44.8 137.4 121.6 137.4 108.3 0 185-52.9 216.6-105.7h10.6l-21.1 95zm-586.7 393.7c-147.7 122.4-337.4 182.1-528.5 166.5-251.1-15.9-430.8-208.8-462.5-412.3H592c58.1 127 198.2 245.8 370 264.3 174.3 21.1 383.2-58.1 496.8-166.5L1332 906.4h383.2l-84.7 407-55.5-158.6-2.6-2.6z"/></svg>
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
            iFood
          </span>
        </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary mx-75' : 'text-danger  mx-75'"
          size="32"
        />
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
.ifood-logo{
  display: inline;
  max-height: 3em;
  
}
.st0{
  fill:#ea1d2c;
}

</style>